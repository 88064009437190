import React, { useEffect, useState } from "react"
import Select from "react-select"
import moment from "moment"
import orderBy from "lodash/orderBy"
import range from "lodash/range"
import sample from "lodash/sample"
import sortBy from "lodash/sortBy"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import CONFIG from "../config"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Badge, Button } from "reactstrap"
import { Option } from "../interfaces/option"
import { QueryName } from "../hasura/queryNames"
import { conceptSelector, ConceptState, fetchRootsAction } from "../hasura/slices/concept"
import { gameSelector, GameState, insertGameAction } from "../hasura/slices/game"
import { games_insert_input } from "../../types/globalTypes"
import { pluralize, postSlackMessage, searchQueryParams } from "../lib/helpers"
import { userSelector, UserState } from "../hasura/slices/user"

// @ts-ignore
import setupGameInfographic from "../lib/images/join-game.svg"

const TIME_LIMIT_OPTIONS = range(1, 10, 2).concat(...(CONFIG.IS_DEVELOPMENT ? [30] : []))

const SUGGESTED = [
  "astr",
  "athl",
  "aud",
  "bi",
  "chrom",
  "chron",
  "crypt",
  "dec",
  "gastr",
  "gon",
  "graph",
  "itis",
  "macro",
  "micro",
  "phil",
  "phon",
  "pod",
  "tempor",
  "tri",
  "vor",
  "zo",
]

export default function SetupGame() {
  const dispatch = useDispatch()

  const [minutes, setMinutes] = useState<number | undefined>()
  const [root, setRoot] = useState<Option | undefined>()

  const { accessToken, user, hasPremium }: UserState = useSelector(userSelector)
  const { isQuerying }: GameState = useSelector(gameSelector)
  const { roots }: ConceptState = useSelector(conceptSelector)

  const rootOptions: Option[] = sortBy(roots, "display_name").map((r) => ({
    label: `${r.display_name.toUpperCase()} - ${r.definition}`,
    value: r.id,
  }))

  useEffect(() => {
    const rootId = searchQueryParams("r")
    if (!roots.length || !rootId) return

    setRoot(rootOptions.find((r) => r.value == rootId))
  }, [roots])

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchRootsAction(accessToken))
  }, [accessToken])

  const handleCreateGame = () => {
    const game: games_insert_input = { root_id: root?.value, minutes, teacher_id: user!.id, join_code: sample(range(1000, 9999)) }
    postSlackMessage(`${user?.display_name} created multiplayer game ${root?.label}.`)
    dispatch(insertGameAction(accessToken!, game))
  }

  const hasStudents = (user?.classrooms || []).some((c) => c.students.length)

  const hasSufficientConcepts = (option: Option) =>
    (roots.find((r) => r.id === option.value)?.root_appearances.filter((a) => hasPremium || a.concept.is_basic)?.length || 0) > 3

  const filteredRootOptions = rootOptions.filter(hasSufficientConcepts)

  const concepts = orderBy(
    roots.find((r) => r.id === root?.value)?.root_appearances.map((r) => r.concept),
    ["obscurity"],
    ["asc"]
  ).map((c) => c.display_name)

  const maxConcepts = 12
  const more = concepts.length - maxConcepts
  const recent = moment(user?.created_at).isAfter(moment().subtract(7, "day"))

  return (
    <Layout>
      <SEO title="Setup Game" />

      <div className="max-width-900px mx-auto pb-5">
        <div className="d-flex justify-content-between">
          <div>
            <h1 className="header mb-2 page-top">Setup Game</h1>

            <p className="text-s text--gray7 m-0 max-width-600px">Make a fast paced game to get everyone to play together.</p>

            <div className="text-s text--gray7 mt-2 ml-2">
              <p className="mb-1">- Select a root and time</p>
              <p className="mb-1">- Students join with a 4-digit code</p>
              <p className="mb-1">- Whoever gets the most correct answers wins! 🏆</p>
            </div>
          </div>

          <img className="mt-3 ml-5" style={{ height: "250px", width: "auto" }} src={setupGameInfographic} />
        </div>

        <div style={{ height: "1px" }} className="w-100 my-4 bg--gray3" />

        <div className="mt-4 min-height-100px">
          <div className="d-flex align-items-center">
            <p className="text-m m-0 gray8 m-0">
              <span className="mr-2 bold">Step 1</span>Select root
            </p>
          </div>

          <div className="d-flex mt-3 align-items-center">
            <Select
              className="width-300px"
              isClearable
              isSearchable
              options={filteredRootOptions}
              placeholder="Root word"
              value={root}
              formatOptionLabel={(option: any) => (
                <div className="d-flex justify-content-between align-items-center">
                  {option.label}
                  {SUGGESTED.includes(option.label.split(" - ")[0].toLowerCase()) && (
                    <Badge color="secondary" className="p-1">
                      Beginner
                    </Badge>
                  )}
                </div>
              )}
              // @ts-ignore
              onChange={(option) => setRoot(option)}
            />

            <Button
              className="ml-2"
              onClick={() =>
                setRoot(
                  sample(recent ? filteredRootOptions.filter((r) => SUGGESTED.includes(r.label.split(" - ")[0].toLowerCase())) : filteredRootOptions)
                )
              }
              color="primary"
            >
              Random
            </Button>
          </div>

          {root && (
            <p className="mt-4 mb-0 text-s max-width-600px">
              <span className="mr-2 text--gray8 bold">Your students will learn words like</span>
              {concepts.slice(0, maxConcepts).join(", ")} and {more > 3 ? `${more} ` : ""}more
            </p>
          )}
        </div>

        <div style={{ height: "1px" }} className="w-100 my-4 bg--gray3" />

        <div className="min-height-100px">
          <div className="d-flex align-items-start">
            <p className="text-m m-0 gray8 m-0">
              <span className="mr-2 bold">Step 2</span>Select duration
            </p>
          </div>

          <div className="mt-3">
            {TIME_LIMIT_OPTIONS.map((m) => {
              return (
                <Button outline={minutes !== m} className="m-1" onClick={() => setMinutes(m)} color="primary" key={m}>
                  {pluralize("minute", m)}
                </Button>
              )
            })}
          </div>
        </div>

        <div className="flex-column mt-4">
          <div className="d-flex align-items-end">
            <Button
              className="min-width-150px"
              size="lg"
              disabled={!root || !minutes || isQuerying[QueryName.InsertGame] || !hasStudents}
              onClick={handleCreateGame}
              color="success"
            >
              Create game code
            </Button>

            {!hasStudents && (
              <p onClick={() => navigate(`/classes?i=${user?.classrooms[0].id}&add=t`)} className="underline pointer text--danger mb-0 ml-2 text-s">
                Add your students first, so they can join your game.
              </p>
            )}
          </div>

          {root && !isQuerying[QueryName.InsertGame] && (
            <Button
              outline
              className="d-block mt-2 min-width-150px"
              onClick={() => {
                const params = `type=demo&st=root&si=${root.value}`
                navigate(`/play?${params}`)
              }}
              color="dark"
            >
              Demo
            </Button>
          )}
        </div>
      </div>
    </Layout>
  )
}
